<template>
  <h3>{{$route.name}}</h3>
</template>

<script>
export default {

}
</script>

<style>

</style>